























































































































































@import '../../assets/scss/common/variables';
.choose_frame_modal {
  .modal-mask {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin: auto;
    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 100%;
    }
  }
  .modal-wrapper {
    display: block;
    height: calc(100% - 8px);
  }
  .modal-container {
    padding: 0;
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
  .modal-body {
    margin: 0;
  }
}

.choose_frame_sale_product {

  &_image {
    width: 100%;
    height: calc(310px * 0.713412);
    overflow: hidden;

    @media screen and (max-width: 767.98px){
      height: calc((100vw - 220px) * 0.713412) !important;
    }

    @media screen and (max-width: 576.98px) {
      height: calc((100vw - 100px) * 0.713412) !important;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }
  }
}

.choose_frame_top {
  background-color: #000000;
  padding: 26px 30px 12px 30px;
  position: relative;
  &_header {
    text-align: center;
    &_title {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &_button {
      position: absolute;
      top: 18px;
      right: 12px;
      border: 1px solid #fff;
      font-size: 12px;
      color: white;
      width: 78px;
      height: 28px;
      line-height: 28px;
    }
  }
}

.choose_frame_main {
  padding: 12px 8px;

  &_title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    background-color: #b90000;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      background-color: #fff;
      color: #000;
      margin-bottom: 8px;
    }
  }

  &_commodity_name {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    margin-top: 16px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      background-color: #fff;
      color: #000;
    }
  }

  &_button_wrapper {
    text-align: center;
  }
  &_button {
    margin: 16px auto 4px;
    border: 2px solid #000000;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
    width: 200px;
    height: 40px;
    text-align: center;
    line-height: 40px;
  }

}

.frame_slider_container {

  height: calc(310px * 0.713412);

  @media screen and (max-width: 767.98px){
    height: calc((100vw - 220px) * 0.713412) !important;
  }

  @media screen and (max-width: 576.98px) {
    height: calc((100vw - 100px) * 0.713412) !important;
  }
  display: grid;
  gap: 0 16px;
  grid-template-columns: 20px 1fr 20px;

  .frame_slider_arrow_left, .frame_slider_arrow_right{
    display: flex;
    align-items: center;
    height: 100%;
  }

  //.frame_slider_image_wrapper {
  //  text-align: center;
  //}
  //.frame_slider_image {
  //  height: 174px;
  //  object-fit: contain;
  //}
}

